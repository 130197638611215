import Banner from "../components/Banner"
import { Text, Image, Box, IconButton, Flex } from "theme-ui"
import { graphql } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/Layout"
import ContentSection from "../components/ContentSection"
import AnimateHeight from "react-animate-height"

import ArrowUp from "../../images/arrowUp.svg"
import SEO from "../components/seo"

interface FaqInterface {
  question: string
  answer: React.ReactNode
}

const qas: FaqInterface[] = [
  {
    question: "What is a Terrace/Balcony Garden?",
    answer: (
      <>
        <Text>
          A Terrace/Balcony garden is a space where you can relax amidst the
          greens, that can be as big or as small as you like, rather the size
          the space permits. It can have a number of pots (big and small), or a
          vertical garden covering the wall space, just some railing baskets or
          hanging baskets in the balcony spaces.
        </Text>
        <Text mt={3}>
          You can have a raised bed in terrace gardens or just a green landscape
          with a beautiful seating space. Both places serve the best to grow a
          variety of plants, container gardening, organic greens, etc.
        </Text>
      </>
    ),
  },
  {
    question: "Will it damage the roof or the walls?",
    answer: (
      <Text>
        It is imperative that waterproofing is impeccable in the areas earmarked
        for developing a terrace and balcony garden.
      </Text>
    ),
  },
  {
    question: "What kind of containers can be used?",
    answer: (
      <Text>
        We are spoiled for choices when it comes to the variety of containers
        available to choose for our terrace gardens. We can use terracotta,
        cement, wood, and fiber planters to add interest to our garden. The key
        is to select the material that suits the area . For example, go with
        lightweight material if the balcony is narrow or not well supported. We
        also have to make sure that the choice of material suits the theme of
        the decor inside the house.
      </Text>
    ),
  },
  {
    question: "What plants can I grow on my terrace and balcony?",
    answer: (
      <Text>
        Terraces and Balconies are an unexplored area for growing our dream
        garden. Depending on the light, these areas can become a veritable
        oasis. We can grow edibles as well as ornamental plants. We can easily
        grow large trees in a container and vegetables, herbs, flowers, and
        other ornamental plants in a raised planter to make our terraces and
        balconies more versatile.
      </Text>
    ),
  },
  {
    question: "Are terrariums easy to maintain?",
    answer: (
      <Text>
        Terrariums are one of the best ways to introduce greenery indoors. If we
        choose the right container and plants and place it in bright light, a
        terrarium can be one of the easiest art pieces to maintain.
      </Text>
    ),
  },
  {
    question: "What is the best way to decorate with Plants?",
    answer: (
      <>
        <Text>
          Plants make perfect living accessories. Right from your tabletops,
          work-desks, any corner spaces, hall entranceway, you can decorate any
          area, big or small with some simple elegant arrangements. Use plants
          on bookcases to add some texture, have plants in hanging baskets in
          corner areas to add visual interest, and draw some eyes.
        </Text>
        <Text mt={3}>
          Plants can make a big space look cozy, turn a simple kitchen into an
          exotic one, make an empty area look lively, and so much more.
        </Text>
        <Text mt={3}>
          All it takes is just one plant to mix and match and play along with
          other elements in and around the house and create something
          innovative.
        </Text>
      </>
    ),
  },
  {
    question: "What plants can be kept indoor?",
    answer: (
      <>
        <Text>
          There are a lot of plants that do great indoors. These are some of the
          easy to care houseplants,
        </Text>
        <ul>
          <li>Snake Plant </li>
          <li>Pothos</li>
          <li>Spider Plant </li>
          <li>Aglaonema</li>
          <li>Red-edged Dracaena </li>
          <li>Red-edged Dracaena </li>
          <li>Red-edged Dracaena </li>
          <li>Peace Lily</li>
          <li>Bamboo Palm</li>
          <li>Ficus</li>
          <li>Dieffenbachia</li>
          <li>Air plant</li>
        </ul>
      </>
    ),
  },
  {
    question: "What kind of container can be used for a container garden?",
    answer: (
      <>
        <Text>
          For a container garden, you can use ceramic or terracotta pots, bonsai
          containers, wooden planters, metal or plastic pots, containers made
          out of concrete as well, as long as they have a drainage hole in them.
          The drainage holes prevent waterlogging in the containers thus
          preventing the roots of the plants to rot.
        </Text>
        <Text mt={3}>
          If your container is a glass bowl, add at least an inch of gravel,
          stone chips. Add activated charcoal as well. This creates an
          artificial drainage layer and the excess water seeps to the bottom of
          the container.
        </Text>
      </>
    ),
  },

  {
    question: "What is the idea behind a Zen garden?",
    answer: (
      <>
        <Text>
          Zen is an important school of Buddhism. It emphasizes contemplation
          and meditation as a means for achieving self-knowledge. Zen gardens
          are designed to help a person achieve the state of mind that leads
          eventually to enlightenment.
        </Text>
        <Text mt={3}>
          Zen gardens are minimalistic with a heavy emphasis on Rocks, Gravel,
          sand, and moss. Most other gardens are all about the plants.
        </Text>
      </>
    ),
  },

  {
    question: "What are Shade plants?",
    answer: (
      <>
        <Text>
          Shade plants are plants that normally grow in a shaded habitat, where
          the intensity of light is low. There are a variety of plants with lush
          foliage that can dwell in the shade. They look stunning in landscape
          or garden setups outdoors, when potted in containers for balconies as
          well as indoors as decorative accents.
        </Text>
        <Text mt={3}>
          A variety of Ferns, Crotons, Philodendrons, Aglaonema make great shade
          plants.
        </Text>
      </>
    ),
  },
  {
    question: "What makes a garden successful?",
    answer: (
      <>
        <Text>
          There are a lot of ingredients that go into making a successful
          garden. The basic ones are healthy soil, the right amount of sunlight,
          the right quantity of water, a little love, and proper care of plants.
        </Text>
        <Text mt={3}>
          However, there are a few more aspects to consider while setting up a
          garden, like the type of garden setup or the theme, the right kind of
          plants, the choice of planters looking at the space, the furniture
          used in the garden area, the lighting used to add a little more
          interest to your space, all these together will make a garden
          successful, a place where you would want to keep coming back to.
        </Text>
      </>
    ),
  },
  {
    question: "What is a good way of arranging plants in a balcony garden? ",
    answer: (
      <>
        <Text>
          Try arranging your plants on certain sides of the balcony, keeping
          free space to move around a little. Place your plants based on the
          height, color, and texture of the plant.
        </Text>
        <Text mt={3}>
          Have the taller plants at the back, or in the corner areas accompanied
          with the middle-sized and shorter plants towards the front.
        </Text>
        <Text mt={3}>
          If using the same colored plants, make sure you have plants with
          different leaf textures. You can also add a fun element with the pop
          of color from a variety of foliage.
        </Text>
      </>
    ),
  },
  {
    question: "What plants can be best used in a container garden? ",
    answer: (
      <>
        <Text>
          You can use a combination of plants in your container garden, from
          succulents to foliage, to flowering plants, depending on the light
          requirements of the plants. All you have to consider is having one
          tall plant to create some drama, called a thriller, followed by more
          bushy plants called fillers to give a more complete look to the
          container garden, and then comes the trailing plants called spillers.
        </Text>
        <Text mt={3}>
          For succulents, you can have Echeveria, Sedum, Kalanchoe, Haworthia,
          etc. You can use foliage plants like Dracaena Mahatma, Selloum,
          Alocasia, Fiddle Fig, etc. as thrillers. A variety of Ferns, Begonias,
          Coleus make good fillers for their lush foliage. Philodendrons,
          English Ivy can be used as spillers.
        </Text>
      </>
    ),
  },
  {
    question: "What could be more sustainable as a planter? ",
    answer: (
      <Text>
        One can always go for things in and around us, like coconut shells. Pick
        coconut shells and use them as planters. One can even use coir planters
        or planters made out of coconut liners, normally placed within planting
        troughs, window boxes, hanging baskets, and other types of containers.
        One can use wooden boxes as planters, planters made out clay, bamboo,
        and jute. These are more eco-friendly and sustainable in all aspects.
      </Text>
    ),
  },
  {
    question:
      "What is the best way to own a garden if there is not enough space? ",
    answer: (
      <Text>
        We can all have our share of green irrespective of the space. If there
        is no floor space, go vertical with plants, use the walls. You can have
        Terrariums which are the best miniature gardens one can own and are
        absolutely easy to care for. Opt for plants that do well with little
        light or indirect bright light and create a green space indoors.
      </Text>
    ),
  },
]

const Faq = (props: FaqInterface) => {
  const { question, answer } = props
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Box sx={{ borderTop: "1px solid black" }} p={[2, 3]}>
      <Flex
        sx={{
          cursor: "pointer",
          justifyContent: "space-between",
          "&:hover .question": { color: "primary" },
        }}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <Text
          className="question"
          color={open ? "primary" : "accent"}
          sx={{ fontWeight: "bold" }}
        >
          {question}
        </Text>
        <IconButton sx={{ outline: "none" }}>
          <Image
            src={ArrowUp}
            sx={{
              transform: open ? "rotate(180deg)" : "none",
              transition: "200ms ease-in",
              cursor: "pointer",
            }}
            alt="arrow"
          />
        </IconButton>
      </Flex>
      <AnimateHeight duration={400} height={open ? "auto" : 0}>
        <Text mt={3} variant="secondary">
          {answer}
        </Text>
      </AnimateHeight>
    </Box>
  )
}

export const fluidImage = graphql`
  fragment faqFluidImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`
export const query = graphql`
  query {
    mobileBanner: file(relativePath: { eq: "faqMobileBanner.png" }) {
      ...faqFluidImage
    }
    desktopBanner: file(relativePath: { eq: "faqDesktopBanner.png" }) {
      ...faqFluidImage
    }
  }
`

export default function Faqs({ data }) {
  return (
    <Layout>
      <SEO title="Frequently Asked Questions" />
      <Banner
        desktopImage={data.desktopBanner.childImageSharp.fluid}
        mobileImage={data.mobileBanner.childImageSharp.fluid}
        heading="Frequently Asked Questions"
        alt="faqs"
      />
      <ContentSection
        heading="Care & Services"
        body={
          <Box sx={{ borderBottom: "1px solid black" }}>
            {qas.map(qa => (
              <Faq question={qa.question} answer={qa.answer} />
            ))}
          </Box>
        }
      />
    </Layout>
  )
}
